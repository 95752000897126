import useIsBrowser from "../hooks/useIsBrowser"
import { navigate } from "gatsby"
import queryString from "query-string"
import { useLocation } from "@reach/router"

// This is till Auto0 stuff loads
const Callback = () => {
  const isBrowser = useIsBrowser()

  // TODO: Possibly do on gatsby-node side to prevent any flash
  const location = useLocation()
  let targetUrl
  if (isBrowser) ({ targetUrl } = queryString.parse(location.search))
  if (targetUrl) {
    // if (targetUrl === "/") {
    //   window.location.assign(process.env.GATSBY_SPARKLE_ADMIN_PATH)
    // } else {
    navigate(targetUrl || "/")
    // }
  }

  // Will show white screen for a sec... I think it is better than jumping content however
  return null
}

export default Callback
